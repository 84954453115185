<template>
  <getecma-single-content-layout>
    <template #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl " />
      <div class="d--flex justify-content--space-between align-items--center">
        <getecma-header class="mt--md">Usuários</getecma-header>
        <div class="d--flex align-items--center" @keyup.enter="onSearchInput">
          <el-input
            v-model="searchQuery"
            placeholder="Buscar usuário"
            class="input-with-select"
            clearable
            @clear="onClearSearch">
            <el-button slot="append" icon="el-icon-search" @click="onSearchInput"></el-button>
          </el-input>
          <getecma-button v-if="isAdmin" style="margin-left: 5%;" @click="onCreateUser">
            Adicionar usuário
          </getecma-button>
        </div>
      </div>
      <div class="d-flex scroll mb--lg justify-content-center" :style="isMinLayout() ? 'height: 50px;':''" @scroll="infinityScroll($event, users)">
        <div v-if="users.data.length" class="w-100">
          <div
            v-for="user in users.data"
            :key="user.id"
            class="w-100 mb--md">
            <getecma-user-card
              :user="user" />
          </div>
        </div>
        <getecma-empty-box v-else description="Nenhum usuário encontrado" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { goToCreateUser, goToUserForbiddenPage } from '@/modules/user/user.routes';
import { fetchUsers } from '@/modules/user/user.service';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { toastError } from '@/services/toastService';
import { infinityScroll } from '@/helpers/scroll/infinity';
import { isMinLayout } from '@/helpers/layout/layout';

import GetecmaUserCard from '@/modules/user/components/UserCard.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaMySpaceUsers',
  components: {
    GetecmaUserCard,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Usuários', path: this.$route.path },
      ],
      users: { data: [], pagination: { page: 1, limit: 20, lastPage: 0 } },
      searchQuery: '',
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  mounted() {
    if (getters.getUser().permission < USERS_ROLES.ADMIN) {
      goToUserForbiddenPage(this.$router, false);
    } else {
      this.fetchUsers();
    }
  },
  methods: {
    isMinLayout,
    fetchUsers() {
      this.users.loading = true;
      fetchUsers({ limit: this.users.pagination.limit, page: this.users.pagination.page, search: this.searchQuery })
        .then(({ users, total_users: total }) => {
          if (this.searchQuery === '') {
            this.users.data.push(...users);
          } else {
            this.users.data = users;
          }
          Object.assign(this.users.pagination, { total: parseInt(total), lastPage: Math.ceil(total / this.users.pagination.limit) });
        })
        .catch(() => toastError('Erro ao obter usuários.'))
        .finally(() => { this.users.loading = false; });
    },
    onCreateUser() {
      goToCreateUser(this.$router);
    },
    infinityScroll(event, content) {
      infinityScroll(event, content)
        .then(() => {
          this.fetchUsers();
        })
        .catch(() => {});
    },
    onSearchInput() {
      this.users.pagination.page = 1;
      this.users.pagination.lastPage = 0;
      this.users.data = [];
      this.fetchUsers();
    },
    onClearSearch() {
      this.searchQuery = '';
      this.onSearchInput();
    },
  },
};
</script>
